/* Main container styling */
.whyFarmsell-container {
    max-width: 1200px;
    margin: 0 auto; 
    padding: 20px;
    margin-bottom: 100px;
    font-family: 'Poppins', sans-serif;
}

/* Section title and subtitle */
.section-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 50px;
    color: #274C5B;
    font-weight: 600;
}

.whyFarmsell-container span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.section-subtitle {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
}

/* Cards container */
.whyFarmsell-container .cards {
    display: flex;
    gap: 20px;
    flex-wrap: wrap; 
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 10px; /* Adjust padding to ensure some spacing around */
}

/* Individual card */
.whyFarmsell-container .Singlecard {
    flex: 1 1 calc(33.333% - 20px); 
    max-width: calc(33.333% - 20px);
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.081);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: none;
}

.whyFarmsell-container .Singlecard:hover {
    transform: translateY(-10px); 
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15); /* Improved hover shadow */
}

/* Card image */
.card-image img {
    width: 100%;
    height: 196px; 
    object-fit: cover; 
    display: block;
}

/* Card content */
.card-contents {
    padding: 15px;
    background-color: white;
    text-align: left; 
    margin-bottom: 10px;
}

.card-titles {
    font-size: 1.2rem;
    margin-bottom: 20px;
    margin-top: 20px;
    color: #05597a;
    font-weight: 600;
}

.card-descriptions {
    font-size: 1rem;
    color: #274C5B;
    line-height: 1.5;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
    .section-title {
        font-size: 1.5rem; 
        margin-bottom: 30px;
        text-align: left;
    }

    .section-subtitle {
        font-size: 1rem; /* Reduce subtitle size for mobile */
        margin-bottom: 20px;
    }

    .whyFarmsell-container .cards {
        flex-direction: column; /* Stack cards vertically */
        gap: 15px; /* Reduce gap between cards */
        padding: 0; /* Remove extra padding */
    }

    .whyFarmsell-container .Singlecard {
        flex: 1 1 100%; /* Make each card take full width */
        max-width: 100%; /* Max width to 100% */
        margin-bottom: 20px; /* Add bottom margin to cards */
    }

    .card-image img {
        height: 180px; /* Adjust image size for smaller screens */
    }

    .card-titles {
        font-size: 1rem; /* Adjust card title size */
    }

    .card-descriptions {
        font-size: 0.95rem; /* Adjust description text size */
    }
}

@media screen and (max-width: 480px) {
    .section-title {
        font-size: 1.5rem; /* Further adjust title size for very small screens */
        margin-bottom: 20px;
    }

    .section-subtitle {
        font-size: 0.9rem; /* Adjust subtitle size for very small screens */
    }

    .whyFarmsell-container .Singlecard {
        padding: 10px; /* Reduce padding on very small screens */
    }

    .card-image img {
        height: 160px; /* Further reduce image size for very small screens */
    }

    .card-titles {
        font-size: 0.9rem; /* Adjust card title size */
    }

    .card-descriptions {
        font-size: 0.9rem; /* Adjust description text size */
    }
}
