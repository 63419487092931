.leadership {
    font-family: 'Poppins', sans-serif;
    padding: 5em 10%;
    background-color: #ffffff; 
    margin-bottom: 50px;
  }
  
  .leadership-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    gap: 3em; 
  }
  
  .image-container img {
    width: 100%;
    max-width: 500px; 
    border-radius: 8px; 
  }
  
  .text-container {
    max-width: 500px; 
  }
  
  .text-container h2 {
    font-size: 2.5rem; 
    font-weight: 600;
    color: #274C5B;
    margin-bottom: 1em;
  }

  .text-container h2 span{
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent ;
  }
  
  .text-container p {
    font-size: 1rem;
    line-height: 1.8;
    color: #525C60;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
   
    .leadership-container {
      flex-direction: column;
      text-align: center;
    }
    .leadership{
        margin-bottom: -40px;
        margin-top: -50px;
    }
  
    .image-container img {
      max-width: 100%; /* Adjust for smaller screens */
    }
    .leadership .text-container{
        width: 100%;
    }

  
    .text-container h2 {
      font-size: 2rem;
    }
  
    .text-container p {
      font-size: 1rem;
    }
  }
  