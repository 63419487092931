.mobile-container{
    padding: 0 10%;
    padding-top: 15%;
    display: flex;
    flex-direction: column-reverse;
    font-family: 'Poppins', sans-serif;
    color: #274C5B;
}

.imagemobile-container{
    width: 100%;
    margin-top: 20px;
}

.mobile-images{
    width: 100%;
}

.textMobile-container{
    width: 100%;

}

.stepsMobile-title{
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), #3FB871, rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 600;
    font-size: 40px;
    text-align: left;
}

.stepsMobile-sub-t{
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), #3FB871, rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 600;
    text-align: left;
    font-size: 30px;
    margin-bottom: 23px;
}

.stepsMobile-p{
    font-size: 18px;
}