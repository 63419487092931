/* OurServices.css */
.our-services{
    background-color: #F9F8F8;
    font-family: 'Poppins', sans-serif;
    padding-bottom: 2em;
    width: 100%; 
}

/* Header styling */
.our-services .header {
    text-align: center;
    padding-top: 5em; 
    width: 100%; 
    max-width: 1200px; 
    margin: 0 auto; 
}

/* Header title */
.our-services .header h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #274C5B;
    margin: 0 auto;
    width: 100%;
    max-width: 1000px; 
}

/* Gradient effect for span in header */
.our-services .header h1 span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent ;
}

/* Description text under header */
.our-services .header .desc-text {
    font-size: 20px;
    text-align: center;
    color: #717171;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px; 
    margin-top: 0.8em; 
    margin-bottom: 40px;
}

/* Content section */
.our-services .content {
    display: flex;
    margin-top: 5em;
    margin-bottom: 5em;
    justify-content: space-between; 
    width: 100%;
    max-width: 1200px; 
    margin: 0 auto;
    padding: 0 2em; 
}

/* Columns inside content section */
.our-services .column {
    flex: 1;
    box-sizing: border-box;
}

/* Each element inside column */
.our-services .element {
    margin-top: 1em;
}

/* Headings inside each element */
.our-services .element h2 {
    font-size: 25px;
    font-weight: 600;
    color: #274C5B;
    margin-bottom: 1em;
}

/* Text inside each element */
.our-services .element .p {
    color: #525C60;
    font-size: 15px;
    /* text-align: left; */
}

/* Adjust margin for lower elements */
.our-services .element.lower {
    margin-top: 3em;
}

/* Icon styling */
.our-services .icon {
    font-size: 24px;
    margin-bottom: 8px;
}

/* Align icons to the right */
.our-services .icon.right {
    display: flex;
    justify-content: flex-end;
}

/* Align text to the right */
.our-services .text.right {
    text-align: right;
}

/* Container for images */
.our-services .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Phone image styling */
.our-services .phoneimage {
    margin: 20px;
    width: 30%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {

    .our-services{
        background: #F9F8F8;;
        margin-top: -90px;
    }

    /* Header text and description adjustments */
    .our-services .header{
        padding: 10px 10px;
        width: 100%;
    }
    .our-services .header h1 {
        font-size: 1.5rem;
        text-align: left;
    }

    .our-services .header .desc-text {
        font-size: 16px;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    /* Stack content vertically for mobile */
    .our-services .content {
        flex-direction: column;
        padding: 0 1em;
    }

    /* Align text to the left */
    .our-services .text.right {
        text-align: left;
    }

    /* Adjust icon positioning */
    .our-services .icon.right {
        justify-content: start;
    }

    /* Hide phone image on mobile */
    .our-services .phoneimage {
        display: none;
    }

    .our-services .element h2 {
        font-size: 1.1rem;
    }

    .our-services .element .p{
        font-size: 14px;
        text-align: left;
    }
}
