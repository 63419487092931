/* General Hero Section styling */
.hero-section {
    background-color: #fff; 
    padding: 50px 20px; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
    font-family: 'Poppins', sans-serif;
}
  
.hero-content {
    max-width: 800px;
    width: 100%; 
    text-align: center; 
}
  
.hero-texts {
    margin-bottom: 30px; 
}
  
.hero-h {
    font-size: 45px; 
    font-weight: 600; 
    line-height: 1.2; 
    color: #274C5B; 
    margin-bottom: 20px; 
}
  
.hero-h .hero-span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}
  
.hero-p {
    font-size: 20px;
    color: #274C5B;
    margin: 10px 0px;
}
  
/* Buttons */
.hero-buttons {
    display: flex;
    justify-content: center;
    gap: 20px; 
    margin-bottom: 50px; 
}
  
.hero-button {
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 600;
    color: #3FB871;
    background: #ffffff;
    border: 1px solid #3FB871;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}
  
.hero-button:hover {
    color: white;
    background: #3FB871; 
}
  
.hero-button.primary {
    background: #3FB871; 
    color: white; /* White text */
    text-decoration:none;
}
  
.hero-button.primary:hover {
    background: #2f975a; 
}
  
/* Image Styling */
.hero-image {
    margin-top: 20px;
}
  
.hero-images {
    max-width: 100%;
    height: auto; 
}
  
/* Responsive Design */
@media screen and (max-width: 768px) {
    .hero-h {
        font-size: 3rem; 
        margin-top: -50px;
    }

    .hero-p {
        font-size: 18px; 
    }

    .hero-buttons {
        flex-direction: column; 
        gap: 15px; 
        align-items: center; 
    }

    .hero-button {
        width: auto; 
        padding: 10px 15px; 
        font-size: 14px; 
    }
}
  
@media screen and (max-width: 480px) {
    .hero-h {
        font-size: 2rem; 
        margin-top: -60px;
    }

    .hero-p {
        font-size: 18px; 
    }

    .hero-buttons {
        flex-direction: column; 
        gap: 10px;
        align-items: center; 
    }

    .hero-button {
        font-size: 12px; 
        padding: 8px 12px; 
        width: auto; 
    }
}
