/* General Styling */
.tailored-benefits {
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  color: #274C5B;
}

/* Header Section */
.tailored-benefits .header {
  text-align: center;
  margin-top: 5em;
  margin-bottom: 5em;
}

.tailored-benefits .header h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #274C5B;
  line-height: 1.2;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.tailored-benefits .header h1 span.colored {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24));
  -webkit-background-clip: text;
  color: transparent;
}

.tailored-benefits .header .desc-text {
  font-size: 1.2rem;
  line-height: 1.4;
  color: #717171;
  margin: 0 auto;
  /* max-width: 60%; */
  margin-bottom: 30px;
  width: 60%;
}

/* Content Section */
.tailored-benefits .content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1em;
  flex-wrap: wrap;
  margin: 0 auto;
}

.tailored-benefits .content .element {
  width: 80%;
  max-width: 600px;
  padding-bottom: 1em;
  margin-bottom: 1em;
  position: relative;
}

/* Align the accordion title with the animated line */
.tailored-benefits .content .accordion-toggle {
  color: #274C5B;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.3s ease;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-left: 20px;
}

.tailored-benefits .content .accordion-toggle:hover {
  color: #05597A;
  transform: translateX(5px);
}

.tailored-benefits .content .accordion-toggle .icon {
  transition: transform 0.3s ease;
}

.tailored-benefits .content .accordion-toggle[aria-expanded="true"] .icon {
  transform: rotate(180deg);
}

/* Add the decorative animated line */
.tailored-benefits .element.open::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 0;
  background: linear-gradient(180deg, #3FB871, rgb(247, 182, 24));
  animation: grow-bar 10s linear forwards;
}

@keyframes grow-bar {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

/* Accordion Content */
.tailored-benefits .content .accordion-content {
  display: none;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  color: #707070;
  line-height: 1.6;
  font-size: 0.95rem;
  padding-left: 20px;
}

.tailored-benefits .content .accordion-content.show {
  display: block;
  opacity: 1;
}

/* Desktop and Phone Images */
.tailored-benefits .content .destop-image,
.tailored-benefits .content .phone-image img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.tailored-benefits .content .destop-image {
  flex: 1;
}

.tailored-benefits .content .phone-image {
  display: none;
  width: 100%;
  margin: 1em 0;
}

.tailored-benefits .content .phone-image img {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.tailored-benefits .content .accordion-image.show {
  transform: scale(1.05);
}

.tailored-benefits .content .accordion-image.hide {
  opacity: 0;
  display: none;
}

/* Fade-in Animation for Images */
.tailored-benefits .destop-image img.fade-in {
  animation: fade-in 1s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* Responsive Design */

/* Tablets */
@media (max-width: 1024px) {
  .tailored-benefits .header h1 {
    font-size: 2rem;
    text-align: left;
  }

  .tailored-benefits .header .desc-text {
    font-size: 16px;
    width: 100%;
    text-align: left;
    
  }

  .tailored-benefits .content {
    flex-direction: column-reverse;
    align-items: center;
  }

  .tailored-benefits .content .destop-image img {
    max-width: 80%;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .tailored-benefits .header h1 {
    font-size: 1.8rem;
  }

  .tailored-benefits .header .desc-text {
    font-size: 16px;
    width: 100%;
  }

  .tailored-benefits .content .accordion-toggle {
    font-size: 1rem;
  }

  .tailored-benefits .content .accordion-content {
    font-size: 0.85rem;
  }

  .tailored-benefits .content .phone-image {
    display: block;
  }

  .tailored-benefits .content .phone-image img {
    max-width: 90%;
  }

  .tailored-benefits .content .destop-image {
    display: none;
  }
}

/* Small Mobile */
@media (max-width: 480px) {
  .tailored-benefits .header h1 {
    font-size: 1.5rem;
  }

  .tailored-benefits .header .desc-text {
    font-size: 16px;
    width: 100%;
  }

  .tailored-benefits .content .accordion-toggle {
    font-size: 0.9rem;
    width: 100%;
  }

  .tailored-benefits .content .accordion-content {
    font-size: 0.8rem;
    width: 100%;
  }

  .tailored-benefits .content .phone-image img {
    max-width: 100%;
  }
}
