.testimonials {
    font-family: 'Poppins', sans-serif;
    padding: 5em 10%;
    background-color: #E6FFDA;
    margin-bottom: 50px;
    text-align: center;
    margin-bottom: 0;
  }
  
  .testimonials .header h2 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #274C5B;
    margin-bottom: 1em;
  }
  
  .testimonials .header h2 .colored {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .testimonial-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0em;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .text-content {
    max-width: 500px;
    text-align: left;
    margin-left: 100px;
  }
  
  .text-content .testimonial-text {
    font-size: 1.5rem;
    line-height: 1.8;
    color: #000000;
    margin-bottom: 1.5em;
    font-weight: 600;
  }
  
  .text-content .testimonial-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #274C5B;
    margin-bottom: 0.5em;
  }
  
  .text-content .testimonial-title {
    font-size: 1rem;
    color: #717171;
  }
  
  .image-content img {
    width: 100%;
    max-width: 500px;
    border-radius: 8px;
  }
  
  /* Navigation Arrows */
  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-top: 2em;
  }
  
  .arrow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #3FB871;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .arrow:hover {
    transform: scale(1.1);
  }
  
  .arrow:focus {
    outline: none;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .testimonial-container {
      flex-direction: column-reverse;
      text-align: center;
    }

    .text-content .testimonial-text {
        font-size: 1.2rem;
    }
  
    .text-content {
      max-width: 100%;
      margin-left: 0px;
      margin-top: 20px;
    }
  
    .image-content img {
      max-width: 100%;
    }
  
    .navigation {
      margin-top: 1.5em;
    }
  }
  