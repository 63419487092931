.c-c {
    background: rgba(220, 255, 235, 1);
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 399px;
    margin-top: 16%;

}



.c-w {
    display: flex;
    margin-left: 10%;
    margin-right: 10%;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

}

.c-img {
    width: 50%;
    justify-content: center;
    align-items: center;
}

.c-images {
    width: 100%;
    height: 213.238px;
    flex-shrink: 0;
}

.c-text {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.c-heading {
    width: 730px;
   
    flex-shrink: 0;
}

.c-heading>h2 {
    color: #3FB871;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
   
}

.c-para {
    margin-top: 20px;
    width: 100%;
    height: 208px;
    flex-shrink: 0;
}

.c-para>p {
    color: #274C5B;
    text-align: justify;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media (max-width: 768px) {

    .c-w {
        margin-top: 20px;
        flex-direction: column;
        justify-content: center;
        gap: 40px;

    }

    .c-para {
        width: 100%;
        height: 208px;
        flex-shrink: 0;
    }

    .c-c {
        margin-top: 20px;
        height: 998px;
    }

    .c-para>p {
        color: #274C5B;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .c-heading {
        width: 100%;
        height: 143px;
        flex-shrink: 0;
    }

    .c-images {
        width: 100%;
        height: 179.238px;
        flex-shrink: 0;
    }

    .c-img {
        width: 100%;
        margin: auto;
        display: flex;

    }

    .c-heading>h2 {
        color: #3FB871;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }


}

@media (max-width: 820px) {}

@media (max-width: 320px) {}