.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Prevent scrolling of content behind the popup */
  z-index: 99999999999999999999; /* Set a higher z-index */
}

.popup-open {
  position: fixed;
  width: 100%;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
}

.clkrtg {
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  color: #3FB871;
}

.mnlpo {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: #525C60;
}

.popup-image {
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  color: #274C5B;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: #3FB871;
  font-family: 'Poppins', sans-serif;
  border: none;
  padding: 0;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .popup-content {
    max-width: 300px;
  }
  
  .clkrtg {
    font-size: 20px; /* Adjust font size */
  }

  .mnlpo {
    font-size: 14px; /* Adjust font size */
  }

  .popup-image {
    max-width: 250px; /* Adjust image width */
  }
}
