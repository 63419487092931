/* CTA Banner Styles */
.cta-banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #95C064;
    border-radius: 10px;
    max-width: 1200px;
    margin: 100px auto;
    gap: 100px;
    flex-wrap: wrap;
}

/* Left Content */
.cta-content {
    flex: 1;
    color: white;
    text-align: left;
    padding: 50px;
    
}

.cta-title {
    font-size: 2.2rem;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 1.3;
}

.cta-title span {
    color: #ffffff;
}

.cta-description {
    font-size: 1rem;
    margin-bottom: 30px;
    line-height: 1.6;
    color: #F7F7F7;
}

.cta-banner .cta-button {
    background-color:#95C064;
    color: #D4ECBA;
    font-size: 1rem;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 8px;
    border: 1px solid #D4ECBA;
    cursor: pointer;
    transition: all 0.3s ease;
}

.cta-banner .cta-button:hover {
    background-color: #D4ECBA;
}

/* Right Image */
.cta-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* width: 50%; */

}

.cta-image img {
    max-height: 100%;
    object-fit: contain; 
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

/* Responsive Design */

/* Tablet View */
@media (max-width: 1024px) {
    .cta-title {
        font-size: 2rem;
    }

    .cta-description {
        font-size: 0.95rem;
    }
}

/* Mobile View */
@media (max-width: 768px) {
    .cta-banner {
        flex-direction: column;
        text-align: center;
        padding: 30px 15px;
    }

    .cta-content {
        padding: 10px;
    }

    .cta-title {
        font-size: 1.8rem;
    }

    .cta-description {
        font-size: 0.9rem;
    }

    .cta-button {
        padding: 12px 25px;
        font-size: 0.9rem;
    }

    .cta-image img {
        margin-top: 20px;
    }
}

/* Small Mobile View */
@media (max-width: 480px) {
    .cta-title {
        font-size: 1.5rem;
    }

    .cta-description {
        font-size: 0.85rem;
    }

    .cta-button {
        font-size: 0.85rem;
    }
}
