.mobile-view-steps {
    display: none;
}

.main-container {
    padding: 0 10%;
    margin-bottom: 200px;
    width: 100%;
}

.hero-how-works {

    padding: 0 10%;
    font-family: 'Poppins', sans-serif;
    background-color: #DEFFEC;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    margin-bottom: 200px;
   

    color: #274C5B;




}

.container-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-cont {
    width: 55%;
}

.how-title {
    font-size: 45px;
    font-weight: 600;
}

.how-para {
    font-size: 25px;
    font-weight: 500;
}

.co {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;


}

.image-cont {
    width: 50%;
    padding-left: 20px;
}

.img-how {
    width: 100%;
}





@media (max-width: 800px) {
    .container-content {
        display: grid;
        justify-content: center;
        align-items: center;
    }

    .hero-how-works {
        padding-top: 30%;

    }

    .text-cont {
        width: 100%;
    }

    .how-title {
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 10%;
    }

    .how-para {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15%;
    }

    .image-cont {
        width: 100%;
        padding-left: 20px;
    }

    .img-how {
        width: 100%;
    }

    .main-container {
        display: none;
    }

    .circle {

        display: none;


    }

    .step-container {
        display: flex;
        flex-direction: column-reverse;
        /* position:absolute; */



    }

    .text-container {
        width: 100%;
        color: #274C5B;
        font-family: 'Poppins', sans-serif;
        text-align: start;
    }

    .mobile-view-steps {
        display: contents;
    }




}







.step-container,
.step-container2 {

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px 0;


}
.step-container{
    /* margin-left: 200px; */
    gap: 150px;
}

.step-image-container {
    width: 50%;
    align-items: end;
    justify-content: end;
    justify-items: end;
    margin-left: 100px;
    
}

.steps-images{
    width: 90%;
    /* margin-left: 200px; */
}


.text-container {
    width: 50%;
    color: #274C5B;
    font-family: 'Poppins', sans-serif;
    /* flex: 1; */
    text-align: start;
    /* margin-left: 10%; */
    
}

.steps-title {
    size: 40px;
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), #3FB871, rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: .5rem;
    


}

.steps-sub-t {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(252, 172, 0), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
    font-size: 25px;
    margin-bottom: 20px;
    text-align: start;
}

.steps-p {
    font-size: 18px;
}

.image-container2 {
    width: 50%;
    margin-left: 100px;
}

.text-container2 {
    width: 50%;
    color: #274C5B;
    font-family: 'Poppins', sans-serif;
    /* text-align: end; */
    text-align: right;
    margin-left: 5%;


}

.steps-title2 {
    size: 40px;
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), #3FB871, rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 600;
    margin-bottom: 25px;
    margin-top: .5rem;


}

.steps-sub-t2 {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(252, 172, 0), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
    font-size: 25px;
    margin-bottom: 20px;
    text-align: end;
}

.steps-p2 {
    font-size: 18px;
    text-align: right;
}


/* Add this to your CSS file */

@keyframes glowing {
    0% {
        box-shadow: 0 0 0 5px #faf601;
    }

    50% {
        box-shadow: 0 0 0 1px #79c006;
    }

    100% {
        box-shadow: 0 0 0 10px #7ee706;
    }
}


.circle {
    width: 30px;
    height: 30px;
    background: #3FB871;
    border-radius: 50%;
    position: absolute;
    right: 45%;
    margin-top: 20px;
    transform: translate(-50%, -50%);
    animation: glowing 2s infinite;
    z-index: 99;
}

.circle-t {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}

.vertical-line {
    border: 4px dashed #D3ECDE;
    width: 6px;
    height: 1500%;
    position: fixed;
    border-radius: 10px;
    margin-left: 42.1%;
    top: 115%;



}

.vertical-line.visible {
    opacity: 1;
}