.life-at-farmsell {
  font-family: 'Poppins', sans-serif;
  padding: 5em 10%;
  background-color: #ffffff;
  text-align: center;
}

.life-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 1em;
}

/* Text Section */
.life-at-farmsell .text-section {
  flex: 1;
  text-align: left;
  margin-left: 80px;
}

.life-at-farmsell .section-title {
  font-size: 2.5rem;
  font-weight: 600;
  color: #274C5B;
  margin-bottom: 1em;
  text-align: left;
}

.section-title .highlight {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
}

.life-at-farmsell .section-subtitle {
  font-size: 1.1rem;
  color: #525C60;
  margin-bottom: 2em;
  line-height: 1.6;
  text-align: left;
}

.cta-button {
  background-color: #3FB871;
  color: white;
  padding: 0.8em 2em;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.cta-button:hover {
  background-color: #274C5B;
  transform: scale(1.05);
}

/* Image Section */
.image-section {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.image-section .carousel {
  display: flex;
  position: relative;
  width: 100%;
  height: 500px;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease;
}

.image-section .carousel-image {
  position: absolute;
  width: 100%;
  max-width: 500px;
  height: auto;
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.8s ease, transform 0.8s ease;
}

.carousel-image.active {
  opacity: 1;
  transform: scale(1);
}

.carousel-image.inactive {
  opacity: 0;
  transform: scale(0.9);
}

/* Responsive Design */
@media (max-width: 768px) {
  .life-at-farmsell {
    padding: 2em 5%;
    margin-top: -90px;
  }

  .life-at-farmsell .life-container {
    flex-direction: column; 
    text-align: center;
    gap: 2em; 
  }

  .life-at-farmsell .text-section {
    text-align: center;
    margin: 0; 
    padding: 0 0%; 
  }

  .life-at-farmsell .section-title {
    font-size: 2rem; 
  }

  .life-at-farmsell .section-subtitle {
    font-size: 1rem; 
  }

  .life-at-farmsell .cta-button {
    width: 100%;
    padding: 1em;
    font-size: 1rem;
  }

  .life-at-farmsell .image-section {
    display: none;
  }
}
