/* General Timeline Container */
.timeline-container {
  width: 100%;
  overflow: hidden; /* Hides overflowing content */
  padding: 100px 0;
  background-color: #ffffff;
  position: relative;
  font-family: 'Poppins', sans-serif;
}

/* Timeline Scroll Section */
.timeline-scroll {
  display: flex;
  gap: 50px; 
  align-items: flex-start;
  animation: scroll-infinite 20s linear infinite; /* Adjust speed as needed */
  will-change: transform;
}

/* Infinite Scrolling Animation */
@keyframes scroll-infinite {
  0% {
      transform: translateX(0%);
  }
  100% {
      transform: translateX(-100%);
  }
}

/* Hover to Pause Animation */
.timeline-container:hover .timeline-scroll {
  animation-play-state: paused;
}

/* Horizontal Line */
.timeline-scroll::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 200%;
  height: 2px;
  background-color: #F1F1F3;
  z-index: 1;
}

/* Individual Timeline Item */
.timeline-item {
  position: relative;
  flex: 0 0 auto;
  width: 250px;
  text-align: left;
}

/* Year Label */
.timeline-year {
  font-size: 1.6rem;
  font-weight: 600;
  color: #274C5B;
  margin-bottom: 10px;
  z-index: 2;
  background: #ffffff;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}

/* Dot Indicator */
.timeline-dot {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  background-color: #274C5B;
}

/* Content Box */
.timeline-content {
  margin-top: 100px;
  padding-left: 20px;
}

.timeline-title {
  font-size: 1rem;
  font-weight: 500;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #f1f1f3;
  color: #274C5B;
}

.timeline-description {
  font-size: 0.9rem;
  color: #000;
  line-height: 1.4;
  margin: 0;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .timeline-item {
      width: 200px;
  }

  .timeline-title {
      font-size: 0.9rem;
  }

  .timeline-description {
      font-size: 0.8rem;
  }
}
