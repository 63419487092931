/* MeetTheVisionary.css */

.combined-container {
    display: flex;
    flex-direction: column;
    background-color: #FFF3D4;
    margin-top: 10%;
    padding: 0 10%;
    /* Adjust padding as needed */
}

.founder-container {
    color: #333;
    padding-top: 10%;
    padding-bottom: 10%;
    margin-left: 4%;
    margin-right: 2%;
}

.founder-header {
    font-size: 45px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #274C5B;
}

.Founding,
.Innovation {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.Cultivat {
    font-size: 35px;
    font-weight: 600;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: left;
    color: #274C5B;
}

.founder-container p {
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0em;
    text-align: justify;
    color: #525C60;
    font-family: 'Poppins', sans-serif;

}

.visionary-container {
    display: flex;
    flex-direction: column;
    padding-bottom: 10%;
    margin-left: 4%;
    margin-right: 2%;

}

.image-contaiiner {
    position: relative;
    margin-bottom: 91px;
}

.image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.vissionery {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.overlay {
    position: absolute;
    width: 100%;
    padding: 10px;
}

.overlay h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    font-family: 'Poppins', sans-serif;
}

.overlay p {
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 3px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;

}

.text-contaiiner {
    text-align: center;
}

.farmes {
    font-size: 43px;
    font-weight: 600;
    line-height: 41px;
    letter-spacing: 0em;
    color: #274C5B;
    text-align: left;
}

.text-containep {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #274C5B;
    margin-top: 10%;
    
    margin-left: 10%;

}

.textntainer {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    color: #274C5B;
    margin-top: 10%;
    text-align: left;
}

/* Media query for small screens */
@media (max-width: 768px) {
    .combined-container {
        padding: 0 5%;
        /* Adjust padding as needed */
    }
    .image-contaiiner {
        position: relative;
        margin-bottom: -15px;
    }

    .founder-header {

        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;

    }
    .text-containep {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        color: #274C5B;
        margin-top: 9%;
        margin-left: 7%;
    }

    .founder-container p {

        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

    }

    .overlay h3 {

        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

    }

    .overlay p {

        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        width: 107%;
        letter-spacing: 0em;
        text-align: left;

    }

    .overlay {

        
        width: 100%;
        padding: -4px;
        margin-bottom :-54px;

    }

    .founder-container {
        margin-right: 0;
        margin-left: 0;
    }

    .visionary-container {
        padding-right: 5%;
        padding-left: 5%;
    }

    .Cultivat {
        display: none;
    }

    .farmes {
        
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;

    }

    .text-contaiiner {
        text-align: left;
        margin-top: 65px;
    }
}

/* Additional styles for large screens */
@media (min-width: 769px) {
    .founder-container {
        text-align: center;
    }

    .visionary-container {
        flex-direction: row;
        justify-content: space-between;
    }

    .text-contaiiner {
        text-align: left;
        max-width: 46%;
        margin-left: auto;
        text-align: justify;
    }
   


}