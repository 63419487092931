.agreement-banner {
    background-color: #3FB871;
    display: flex;
    justify-content: center;
    align-items: center;
    font: Inter;
    padding-top: 5em;
    padding-bottom: 5em;

}

.agreement-banner .sect-title {
    margin-left: 10%;
    width: 50%;
}

.agreement-banner .sect-title h1 {
    color: white;
    font-size: 40px;
    font-weight: 700;
}

.agreement-banner .sect-title p {
    color: white;
    font-size: 30px;
    font-weight: 400;
    margin-top: 2em;
    margin-bottom: 2em;
}

.agreement-banner .sect-title .link-button {
    background-color: #F7B618;
    border-radius: 5px;
    padding: 10px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
}

.agreement-banner .sect-image {
    margin-right: 10%;
    width: 50%;
}

.agreement-content {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    margin-bottom: 20em;
    font: Inter;

}

.agreement-content .an-element {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;

}

.agreement-content .an-element ul {
    list-style-type: disc;
}

.agreement-content .an-element ul li,
.agreement-content .an-element p {
    font-size: 18px;
    font-weight: 400;

}

.agreement-content .an-element h1 {
    color: #3FB871;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 1em;
}

@media (max-width: 768px) {
    .agreement-banner{
        flex-direction: column;
    }
    .agreement-banner .sect-title{
        width: 90%;
    }

    .agreement-banner .sect-image{
        width: 90%;
    }
    .agreement-content .an-element {
        width: 80%;
        
    
    }
}
