/* General Styling */
.corevalues {
  font-family: 'Poppins', sans-serif;
  background-color: #F1F1F3;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5em;
  max-width: 1200px; 
  margin: 200px auto; 
  border-radius: 10px;
}

.corevalues .header {
  text-align: center;
  padding-top: 5em;
}

.corevalues .header h2 {
  font-size: 45px;
  font-weight: 600;
  color: #274C5B;
  text-align: center;
}

.corevalues .header h2 span {
  background: linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.corevalues .header .desc-text {
  font-size: 18px;
  text-align: center;
  color: #717171;
  margin-left: 10%;
  margin-right: 10%;
  max-width: 800px;
  margin: 0 auto;
}

/* Menu and Details Container */
.corevalues .menu-details-container {
  display: flex;
  margin-top: 4em;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

/* Menu Container */
.corevalues .menu-container {
  width: 40%;
  margin-right: 5%;
  padding: 10px;
}

.corevalues .menu-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.corevalues .menu-container li {
  display: flex;
  align-items: center;
  color: #274C5B;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.3s;
  border-left: 8px solid #F1F1F3;
  margin-bottom: 10px;
}

.corevalues .menu-container li .ca-active {
  width: 8px;
  height: 30px;
  border-radius: 5px;
  margin-right: 1em;
}

.corevalues .menu-container li.active {
  background: linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.corevalues .menu-container li.active .ca-active {
  background-color: #3FB871;
}

/* Details Container */
.corevalues .details-container {
  flex: 1;
  padding: 2em;
  border-radius: 5px;
  border: 2px solid #3FB871;
  max-width: 600px;
  width: 100%;
}

.corevalues .details-container h2 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  background: linear-gradient(103deg, #3FB871 1.36%, #3FB871 32.41%, #F7B618 67.78%, #3FB871 97.52%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.corevalues .details-container p {
  color: #525C60;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
}

/* Mobile Styles */
.corevalues .for-mobile {
  display: none;
}

@media (max-width: 768px) {
  .corevalues .for-web,
  .corevalues .menu-container {
    display: none;
  }

  .corevalues .for-mobile {
    display: unset;
  }

  .corevalues .header h2 {
    font-size: 30px;
    text-align: left;
  }

  .corevalues .header .desc-text {
    font-size: 16px;
    text-align: left;
    margin-left: 0;
    margin-right: 0;
  }

  .corevalues .menu-details-container {
    flex-direction: column;
  }

  .corevalues .menu-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .corevalues .menu-container li {
    font-size: 16px;
    padding: 10px;
  }

  .corevalues .details-container {
    width: 100%;
    margin-bottom: 27px;
  }

  .corevalues .details-container h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .corevalues .details-container p {
    font-size: 14px;
  }
}
