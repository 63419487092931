.banner{
 
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    border-radius: 40px;
    margin: 0 10%;
    display: flex;
    margin-top: 100px;
    margin-bottom: 100px;
}

.banner .left-ban{
   padding-top: 13%;
   width: 100%;

}
.banner-title {
    font-size: 60px;
    color:#274C5B ;
    margin-left: 15%;
    font-weight: 600;
    width: 100%;
    margin-bottom: 20px;
    line-height: 100%;
    
     

}

.title-top{
    font-size: 60px;
    color:#274C5B ;
    margin-left: 15%;
    margin-bottom: 1%;
    font-weight: 600;
    
}

.banner-span{
    color: white;
}

.banner-button{
    margin-left: 15%;
    font-size: 20px;
    font-weight: bold;
    padding: 18px 40px;
    border-radius: 10px;
    border: none;
    background-color: #F7B618;
    color: white;
    cursor: pointer;
    margin-top: 40px;



}

.right-ban{
  width: 100%;
}

.image-banner{
    width: 60%;
    margin-left: 150px;
    margin-top: 78px;
}

@media (max-width: 768px) {

    .banner{
        display: none;
    }

}

@media (max-width: 820px) {

    .banner{
        display: none;
    }

}

@media (max-width: 1280px) {
    .image-banner{
        width: 60%;
        margin-left: 150px;
        margin-top: 100px;
    }

    .banner-button{
        margin-left: 100px;
        font-size: 20px;
        font-weight: bold;
        padding: 18px 40px;
        border-radius: 10px;
        border: none;
        background-color: #F7B618;
        color: white;
        cursor: pointer;
        margin-top: 40px;
    
    
    
    }

    .banner-title, .title-top {
        font-size: 55px;
      
       
    
        
      
        
         
    
    }

}