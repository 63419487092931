.input-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  align-items: center;
  margin-top: -8%;
  margin-left: 24%;
  margin-right: 20%;
}

.ReactModal__Overlay {
  z-index: 9999999999999999999 !important;
}
.ReactModal__Content {
  padding: 0 !important;
}

.signupMainDiv {
  margin: 0px auto;
  width: 100%;
  min-width: 230px;
  max-width: 600px;
}

.welcome-video-mob {
  display: none !important;
}

.Zc_SignupSuccess {
  display: none;
  position: absolute;
  margin-left: 4%;
  width: 90%;
  background-color: white;
  padding: 3px;
  border: 3px solid rgb(194, 225, 154);
  margin-top: 10px;
  margin-bottom: 10px;
  word-break: break-all;
}

.SIGNUP_HEADING {
  color: rgb(255, 255, 255);
  background-color: rgb(247, 182, 24);
  word-break: break-word;
  padding: 20px;
  margin: 0px;
  border-color: rgb(255, 255, 255);
  font-family: Arial;
  text-align: center;
  border-width: 1px;
  font-size: 20px;
  border-style: none;
}

.SIGNUP_BODY {
  background-color: rgb(64, 183, 113);
  color: rgb(255, 255, 255);
  padding: 20px;
  font-family: Arial;
  text-align: center;
  bottom: 2px;
  font-size: 12px;
  opacity: 1;
}

.errorMsgDiv {
  display: none;
  background-color: #ffebe8;
  padding: 10px 10px;
  color: #d20000;
  font-size: 11px;
  margin: 10px 0px;
  border: solid 1px #ffd9d3;
  margin-top: 20px;
}

.SIGNUP_PAGE {
  background-color: rgb(255, 255, 255);
  color: rgb(187, 187, 187);
  padding: 30px;
  font-family: Arial;
  text-align: center;
  font-size: 14px;
}

.imgBlock {
  width: 600px;
  margin: 0px auto;
  font-family: Arial, Helvetica, sans-serif;
  padding-bottom: 10px;
  color: rgb(68, 68, 68);
  text-align: center;
  font-size: 12px;
  padding-top: 10px;
  font-weight: bold;
}

.iframeImg {
  width: 184px;
  max-width: 100%;
  height: auto;
}

.signupSuccessMsg {
  color: rgb(73, 140, 132);
  font-family: sans-serif;
  font-size: 14px;
  word-break: break-word;
}

.SIGNUP_FORM_LABEL {
  color: rgb(255, 255, 255);
  font-family: Arial;
  font-size: 13px;
}

.SIGNUP_FORM_FIELD {
  width: 97%;
  color: rgb(100, 100, 100);
  background-color: rgb(237, 237, 237);
  border: 3px solid rgb(255, 255, 255);
  padding: 3px;
  font-family: Arial;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 14px;
  text-indent: 5px;
  height: 28px;
}

.SIGNUP_REQUIRED {
  color: rgb(255, 255, 255);
  font-family: Arial;
  font-size: 13px;
}

.SIGNUP_SUBMIT_BUTTON {
  cursor: pointer;
  appearance: none;
  color: rgb(255, 255, 255);
  background-color: rgb(247, 182, 24);
  white-space: normal;
  padding: 5px 15px 5px 0px;
  border-color: rgb(102, 0, 0);
  text-align: center;
  outline: none;
  font-family: Arial;
  border-radius: 3px;
  border-width: 1px;
  font-size: 16px;
  background-position: center bottom;
  border-style: none;
  background-repeat: repeat-x;
}

.SIGNUP_REQUIRED {
  color: rgb(180, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}

.CONTACT_EMAIL {
  width: 97%;
  color: rgb(100, 100, 100);
  background-color: rgb(237, 237, 237);
  border: 3px solid rgb(255, 255, 255);
  padding: 3px;
  font-family: Arial;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 14px;
  text-indent: 5px;
  height: 28px;
}

.SIGNUP_FORM_LABEL {
  color: rgb(255, 255, 255);
  font-family: Arial;
  font-size: 13px;
}

.SIGNUP_FORM_LABEL {
  color: rgb(255, 255, 255);
  font-family: Arial;
  font-size: 13px;
}

.SIGNUP_REQUIRED {
  color: rgb(180, 0, 0);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
}

.SIGNUP_FORM_FIELD {
  width: 97%;
  color: rgb(100, 100, 100);
  background-color: rgb(237, 237, 237);
  border: 3px solid rgb(255, 255, 255);
  padding: 3px;
  font-family: Arial;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 14px;
  text-indent: 5px;
  height: 28px;
}

.zcOptinOverLay {
  display: none;
  text-align: center;
  background-color: rgb(0, 0, 0);
  opacity: 0.5;
  z-index: 100;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 988px;
}

.zcOptinSuccessPopup {
  display: none;
  z-index: 9999;
  width: 800px;
  height: 40%;
  top: 84px;
  position: fixed;
  left: 26%;
  background-color: #ffffff;
  border-color: #e6e6e6;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 1px 10px #424242;
  padding: 35px;
}

.closeSuccess {
  position: absolute;
  top: -16px;
  right: -14px;
  z-index: 99999;
  cursor: pointer;
}

.captchaParent {
  width: 59%;
  float: left;
  min-width: 170px;
  max-width: 70%;
}

.captchaDiv {
  padding: 20px;
  background: #fff;
  border: 1px solid rgb(222, 222, 222);
  box-sizing: border-box;
  width: 98.8%;
}

.input-form input[type="text"],
.input-form input[type="email"],
.input-form input[type="password"] {
  margin-bottom: 10px;
  padding: 8px;
  border: none;
  /* Removed border */
  border-radius: 4px;
  background-color: #f5f5f5;
  width: 420px;
  height: 50px;
  outline: none;
  font-family: "Poppins", sans-serif;
  font-size: 18;
  font-weight: 400;
}

/* Adjust styles for placeholder text */
.input-form input::placeholder {
  color: #939393;
  font-family: "Poppins", sans-serif;
  font-size: 18;
  font-weight: 400;
  text-align: left;
}

.custom-validation {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  margin-bottom: 10px;
  text-align: center;
}

.input-form button[type="submit"] {
  margin-bottom: 10px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #3fb871;
  width: 420px;
  height: 50px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.input-form button[type="submit"]:hover {
  background-color: #368a63;
  /* Adjusted color on hover */
}

.hey {
  font-family: "Poppins", sans-serif;
  font-size: 35px;
  /* Added the unit 'px' */
  font-weight: 600;
  margin-left: 11%;
}

.hey3 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  /* Added the unit 'px' */
  font-weight: 400;
  margin-left: -32%;
  margin-bottom: -3%;
}

.hey4 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  /* Added the unit 'px' */
  font-weight: 500;
  margin-top: 2%;
}

.linear-color {
  background: linear-gradient(to left, #3fb871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
}

.footer-style-reg {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 36%;
  padding: 8px;
  border: none;
  /* Removed border */
  border-radius: 4px;
  background-color: #f5f5f5;
  width: 420px;
  height: 50px;
  outline: none;
}

.terms-reg {
  display: flex;
  margin-left: 10px;
  align-items: center;
  margin-top: 10px;
}

.terms-reg-container {
  display: flex;
  margin-bottom: 200;
  margin-left: 35%;
  align-items: center;
}

.reg-line {
  color: #000000;
  width: 10%;
  display: inline-block;
  margin: 0 17px;
}

.welcome-video-web {
  display: flex;
  flex-direction: row;

  align-items: center;
  display: block;
}

.welcome-page-web {
  display: block;
}

.welcome-content-web {
  margin-top: -15%;
  width: 40%;
  height: 40%;
  margin-left: 5%;
  display: block;
}

.welcome-page-mobile {
  display: none;
}

.join-page-heading1 {
  display: block;
}

.join-page-heading-mobile {
  display: none;
}

.btn-google {
  width: 420px !important;
  height: 54px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;

  background-color: rgba(245, 245, 245, 1) !important;
}

.custom-flexxs-row {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .input-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 8px;
    align-items: center;
    margin-top: -112px;
    margin-left: 10px;
    margin-right: 10px;
    width: 90% !important;
    padding: 0px !important;
    margin-left: 30px;
  }

  .ReactModal__Content {
    padding: 0 !important;
    width: 80% !important;
  }
  .form-control{
    width: 100% !important;
  }
  

  .phone-input-field {
    width: 100% !important;
  }

  .input-custom-style {
    width: 100% !important;
  }

  .input-form button[type="submit"] {
    margin-bottom: 10px;
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: #3fb871;
    width: 98%;
    height: 50px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    outline: none;
  }

  .reg-header-mobile {
    margin-left: -14px !important;
    margin-right: 0px !important;
    width: 100%;
  }

  .join-page-heading1 {
    display: none;
  }

  .join-page-heading-mobile {
    display: block;
    margin-left: 47px;
  }

  .welcome-video-mob {
    display: block !important;
  }

  .footer-style-reg {
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 30px;
    padding: 8px;
    border: none;
    /* Removed border */
    border-radius: 4px;
    background-color: #f5f5f5;
    width: 420px;
    height: 50px;
    outline: none;
  }

  .terms-reg {
    display: flex;
    margin-left: 0px;
    align-items: center;
    margin-bottom: 20px;
    
  }

  .terms-font {
    font-size: 15px;
  }

  .btn-google {
    width: 90% !important;
    margin-top: -83px !important;
    background-color: rgba(245, 245, 245, 1) !important;
  }

  .terms-reg-container {
    display: flex;
    margin-bottom: 200;
    margin-left: 30px;
    align-items: center;
  }

  .reg-line {
    color: #000000;
    width: 38%;
    display: inline-block;
    margin: 0 17px;
  }

  .welcome-video-web {
    display: none;
  }

  .welcome-video-mob {
    display: block;
  }

  .welcome-content-web {
    display: none;
  }

  .welcome-page-web {
    display: none;
  }

  .welcome-page-mobile {
    display: block;
  }

  .custom-flexxs-row {
    display: block !important;
  }

  .berdsec {
    margin-top: 100%;
    margin-bottom: 10%;
  }

  .kledsa {
    margin-top: 2% !important;
  }

  #sedcxs {
    margin-top: -75% !important;
    margin-left: -79% !important;
    font-weight: 400 !important;
    font-size: 19px !important;
    white-space: nowrap;
  }

  .hey {
    margin-top: 5% !important;
    margin-bottom: 2% !important;
    font-size: 24px !important;
    font-weight: 600;
  }

  .nmelsdex {
    margin-top: 100% !important;
    width: 77% !important;
    height: 50%;
    margin-left: 15%;
  }
  .hey3 {
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-left: -48% !important;
    margin-bottom: 33%;
  }
}
