.verify-email-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 180px;
    padding: 20px;
}

.ReactModal__Content.ReactModal__Content--after-open {
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 50%;
    display: flex;
    
    align-items: center;
    justify-content: center;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.verify-email-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.verify-email-text {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #274c5b;
    text-align: center;
}

.combinedInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.verify-email-input-container {
    margin-bottom: 20px;
}

.verify-email-button {
    padding: 10px 95px;
    background: #3FB871;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.verify-email-footer {
    margin-top: 20px;
}

/* Media query for small screens */
@media (max-width: 768px) {
    .verify-email-text {
        font-size: 13px;
        width: 89%;
        text-align: left;
    }
    .verify-email-subheade{
        display: none;
    }
    .verify-email-foote{
        text-align: center !important;
        display: inline;

        
    }
    .vrtyt{
        text-align: center !important;
        display: block !important; 
    }

    .verify-email-header {
        font-size: 17px;
        text-align: center;
    }

    .ReactModal__Content.ReactModal__Content--after-open {
        inset: 43% auto auto 47%;
        width: 80% !important;
    }

    .verify-email-button {
        padding: 13px 58px;
        margin-top: 20px;
        margin-left: 0;
    }

    .verify-email-input-container {
        margin-left: 0;
        margin-right: 0;
    }

    .verify-email-container {
        padding: 10px;
        display: block;
    }
}

/* Media query for iPadOS */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .verify-email-text {
        font-size: 15px;
        width: 77%;
        text-align: center;
    }
    .verify-email-subheade{
        display: none;
    }
    .verify-email-foote{
        text-align: center !important;
        display: inline;
    }
    .vrtyt{
        text-align: center !important;
        display: block !important; 
    }

    .verify-email-header {
        font-size: 17px;
        text-align: center;
    }

    .ReactModal__Content.ReactModal__Content--after-open {
        inset: 43% auto auto 47%;
        width: 80% !important;
    }

    .verify-email-button {
        padding: 13px 58px;
        margin-top: 20px;
        margin-left: 0;
    }

    .verify-email-input-container {
        margin-left: 0;
        margin-right: 0;
    }

    .verify-email-container {
        padding: 10px;
        display: block;
    }
}

.custom-verification-input input {
    width: 30px;
    height: 30px;
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
}

@media only screen and (max-width: 768px) {
    .custom-verification-input input {
        width: 25px;
        height: 25px;
        font-size: 12px;
        padding: 3px;
    }

    div[data-testid="container"].vi__container {
        position: relative;
        display: flex;
        gap: 8px;
        height: 19px !important;
        width: 181px !important;
    }
}

.custom-verification-input .vi {
    top: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    position: absolute;
    color: transparent;
    background: transparent;
    caret-color: transparent;
    outline: none;
    border: none;
}

div[data-testid="container"].vi__container {
    position: relative;
    display: flex;
    gap: 8px;
    height: 50px;
    width: 267px;
}
