.welcome-container {
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
  gap: 5rem;
  width: 100%;
  max-width: 1200px; 
  margin: 100px auto; 
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.text-content {
  /* flex: 1;  */
  max-width: 40%;
}

.text-content h1 {
  font-size: 2.5rem;
  color: #274C5B;
  margin-bottom: 1rem;
  font-weight: 600;
}

.text-content h1 .gradient-text {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
}

.text-content p {
  font-size: 1.1rem;
  color: #274C5B;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.learn-more-btn {
  /* display: flex; */
  align-items: center;
  background-color: #ffffff;
  color: #3FB871;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid #3FB871;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.learn-more-btn:hover {
  background-color: #3FB871;
  color: #ffffff;
}

.image-container {
  flex: 1; 
  /* max-width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container .responsive-image {
  width: 100%;
  /* height: auto; */
  object-fit: cover;
}

@media (min-width: 1200px) {
  .image-container .responsive-image {
    max-width: 600px; 
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .welcome-container {
    flex-direction: column; /* Stack content vertically */
    gap: 3.5rem;
    padding: 1.5rem; 
    margin-top: -30px;
  }

  .text-content, .image-container {
    max-width: 100%; /* Full width on smaller screens */
   
  }

  .text-content h1 {
    font-size: 1.5rem;
  }

  .text-content p {
    font-size: 1rem;
  }

  .learn-more-btn {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }

  .image-container .responsive-image {
    max-width: 100%; 
    margin-left: 100px;
  }
}
