/* General Section Styling */
.trusted-clients-section {
  width: 100%;
  padding: 50px 20px;
  background-color: #fff;
  margin-top: -50px;
  font-family: 'Poppins', sans-serif;
}

.section-header {
  margin-bottom: 40px;
}

.section-header .title {
  font-size: 30px;
  font-weight: 600;
  color: #274C5B;
  margin-bottom: 10px;
}

.section-header .title .highlight {
  background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113));
  -webkit-background-clip: text;
  color: transparent;
}

.section-header .subtitle {
  font-size: 18px;
  color: #274C5B;
}

/* Slider Container */
.client-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: -10px;
}

.client-logo-card {
  width: 200px; 
  height: 80px; 
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.042); 
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.client-logo {
  max-width: 100%; 
  max-height: 100%; 
  object-fit: contain; 
}

/* Remove slick-carousel default arrows */
.slick-prev, .slick-next {
  display: none !important;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .section-header .title {
    font-size: 28px;
  }

  .section-header .subtitle {
    font-size: 16px;
  }

  .client-logo-card {
    width: 150px;
    height: 100px;
  }
}

@media screen and (max-width: 480px) {
  .section-header .title {
    font-size: 24px;
  }

  .section-header .subtitle {
    font-size: 14px;
  }

  .client-logo-card {
    width: 120px;
    height: 80px;
  }
}
