.our-story-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 2rem;
    gap: 8rem;
    margin: 2rem auto;
    max-width: 1200px;
    height: auto;
    font-family: 'Poppins', sans-serif;
  }
  
  .our-story-image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .our-story-image {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .our-story-content {
    flex: 1;
    max-width: 600px;
  }
  
  .our-story-title {
    font-size: 2.5rem;
    font-weight: 600;
    color: #274c5b;
    margin-bottom: 1rem;
  }

  .our-story-title span{
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent ;
  }
  
  .our-story-description {
    font-size: 1rem;
    line-height: 1.8;
    color: #555;
    margin-bottom: 2rem;
  }
  
  .our-story-buttons {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap; /* Ensure buttons wrap on smaller screens */
  }
  
  .learn-more-btn,
  .join-us-btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .learn-more-btn {
    background-color: #3fb871;
    color: #fff;
  }
  
  .learn-more-btn:hover {
    transform: scale(1.05);
  }
  
  .join-us-btn {
    background-color: #274c5b;
    color: #fff;
  }
  
  .join-us-btn:hover {
    transform: scale(1.05);
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .our-story-section {
      gap: 4rem;
    }
  
    .our-story-title {
      font-size: 2rem;
    }
  
    .our-story-description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .our-story-section {
      flex-direction: column; /* Stack image and content vertically */
      text-align: center;
      gap: 2rem;
    }
  
    .our-story-content {
      order: 2; /* Move content below the image */
    }
  
    .our-story-image-container {
      order: 1; /* Move image above content */
    }
  
    .our-story-title {
      font-size: 1.8rem;
    }
  
    .our-story-description {
      font-size: 0.85rem;
    }
  
    .our-story-buttons {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .our-story-title {
      font-size: 1.5rem;
    }
  
    .our-story-description {
      font-size: 0.8rem;
    }
  
    .learn-more-btn,
    .join-us-btn {
      font-size: 0.9rem;
      padding: 0.5rem 1rem;
    }
  }
  