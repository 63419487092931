.ourachivements {
    font-family: 'Poppins', sans-serif;
    margin-left: 10%;
    margin-right: 10%;
}

.ourachivements .header {
    text-align: center;
    margin-top: 10em;
    margin-bottom: 5em;
}

.ourachivements .header h2 {
    font-size: 45px;
    font-weight: 600;
    color: #274C5B;
    text-align: center;
}


.ourachivements .header h2 span.colored {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.ourachivements .header .desc-text {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #717171;
    margin-left: 10%;
    margin-right: 10%;
}

.our-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}


.our-container .item {
    flex: 0 0 calc(33.33% - 20px);
    margin: 10px;
    margin-top: 10%;
}

.our-container .item .icon{
    display: flex;
    justify-content: center;
}
.our-container .item .num {
    color: #274C5B;
    text-align: center;
    font-size: 50px;
    font-weight: 600;
}

.our-container .item .desc {
    color: #274C5B;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}


@media (max-width: 768px) {
    .our-container .item {
        flex: 0 0 calc(50% - 20px);
    }

    .ourachivements .header h2 {
        font-size: 30px;
        text-align: left;
    }
    .ourachivements .header .desc-text {
        font-size: 18px;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
    }
    .our-container {
        margin-left: -5%;
        margin-right: -5%;
    }
    
    .our-container .item .icon img{
        width: 55%;
    }

    
    .our-container .item .num {
        font-size: 40px;
    }
    
    .our-container .item .desc {
        font-size: 18px;
    }


}