/* Pop-up Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.584);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px; 
    overflow-y: auto; 
}

/* Pop-up Container */
.popup-container {
    position: relative; 
    background: #fff;
    width: 90%; 
    max-width: 700px; 
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
    animation: fadeIn 0.3s ease-in-out;
    display: flex;
    flex-direction: column; 
}

@media (min-width: 768px) {
    .popup-container {
        flex-direction: row; 
        height: 60%; 
    }
}

/* Text Section */
.popup-container .popup-text {
    padding: 20px 50px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center; 
}

.popup-container .popup-text .popup-title {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 10px;
}

.popup-container .popup-text .popup-para {
    font-size: 16px;
    margin-bottom: 20px;
}

.popup-container .popup-text .cta-button {
    background-color: #3FB871;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 20px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

.cta-button:hover {
    background-color: #F7B618;
    color: #333;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
}

.cta-button:focus {
    outline: 2px solid #F7B618;
    outline-offset: 2px;
}

.popup-container .popup-text .legal-popup {
    font-size: 12px;
    margin-top: 10px;
}

.popup-container .popup-text .popup-link {
    color: #3FB871;
    text-decoration: underline;
}

.popup-container .popup-text .popup-link:hover {
    color: #F7B618;
}

/* Image Section */
.popup-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    min-height: 200px;
}

.popup-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out; 
}



/* Mobile-Specific Styling */
@media (max-width: 767px) {
    .popup-container {
        flex-direction: column;
        height: auto;
        border-radius: 10px; 
        width: 90%;
        margin: 0;
    }

    .popup-image {
        display: none; 
    }

    .popup-container .popup-text {
        padding: 15px;
        margin-top: 50px;
    }

    .popup-container .popup-text .popup-title {
        font-size: 25px;
    }

    .popup-container .popup-text .cta-button {
        font-size: 14px;
        padding: 10px;
    }

    .popup-container .popup-text .legal-popup {
        font-size: 11px;
    }

    

  
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 6px;
    right: 5px;
    background: #3FB871;
    border: none;
    border-radius: 50%;
    font-size: 18px;
    color: #ffffff;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    transition: all 0.3s ease-in-out;
    z-index: 452;
}

.close-btn:hover {
    background: #e74c3c;
    color: #fff;
}

.close-btn:active {
    transform: scale(0.9); 
}

.close-btn:focus {
    outline: 2px solid #F7B618;
    outline-offset: 2px;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
