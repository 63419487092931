/* our approach styles */

.ourapproach {
    font-family: 'Poppins', sans-serif;
    background: var(--linear-two, linear-gradient(103deg, rgba(63, 184, 113, 0.3) 1.36%, rgba(63, 184, 113, 0.3) 32.41%, rgba(247, 182, 24, 0.3) 67.78%, rgba(63, 184, 113, 0.3) 97.52%));
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 10em;
}

.ourapproach .header {
    text-align: center;
    padding-top: 5em;
    padding-bottom: 5em;
}

.ourapproach .header h2 {
    font-size: 45px;
    font-weight: 600;
    color: #274C5B;
    font-weight: 600;
    text-align: center;
}


.ourapproach .header h2 span.colored {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

.ourapproach .header .desc-text {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: #717171;
    margin-left: 10%;
    margin-right: 10%;
}

.ourapproach .our-approach-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ourapproach .box-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.ourapproach .box-container h2 {
    color: #274C5B;
    font-size: 20px;
    font-weight: 600;
    height: 75px;
}

.ourapproach .box-container p {
    color: #525C60;
    font-size: 18px;
    font-weight: 400;
}

.ourapproach .content-box,
.ourapproach .content-box2 {
    width: 47%;
}

.ourapproach .lower-sect {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
    margin-bottom: 2.5em;
}

.ourapproach .navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-left: 2.5em;
}

.ourapproach .prev-button {
    display: inline-flex;
    height: 46px;
    padding: 17px 27px 18px 27px;
    justify-content: center;
    align-items: center;
    border-radius: 72px;
    background: #3FB871;
    border: none;
}

.ourapproach .next-button {
    display: inline-flex;
    height: 46px;
    padding: 17px 27px 18px 27px;
    justify-content: center;
    align-items: center;
    border-radius: 72px;
    background: #EFEFEF;
    color: #263238;
    border: none;
    margin-left: 1em;
}

.ourapproach .next-button span {
    padding-left: 10px;
}

.ourapproach button.disabled {
    pointer-events: none;
}

.ourapproach .dot-container {
    display: flex;
}

.ourapproach .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #C7C7C7;
    margin: 0 5px;
}

.ourapproach .dot.active {
    background-color: #3FB871;
}

/* Media query for mobile */
@media (max-width: 768px) {
    .ourapproach .header h2 {
        font-size: 30px;
        font-weight: 600;
        text-align: left;
    }
    .ourapproach .header .desc-text {
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
    }

    .ourapproach .box-container {
        flex-direction: column;
    }


    .ourapproach .content-box {
        width: 100%;
        margin-bottom: 20px;
    }

    .ourapproach .box-container h2{
        font-size: 18px;
    }
    .ourapproach .box-container p{
        font-size: 15px;
    }

    .ourapproach .content-box2 {
        display: none;
    }

    .ourapproach .lower-sect{
        flex-direction: column-reverse;
               
    }
    .ourapproach .navigation-buttons{
        margin-left: 0;
        margin-top: 2em;
    }
}