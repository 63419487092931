/* General styling for the entire testimonial page */
.testimonialPage {
    background-color: #fff;
    padding: 50px 0;
    font-family: 'Poppins', sans-serif;
  }
  
  .testimonialHeader {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .testimonialHeader h1 {
    font-size: 2.5rem; 
    font-weight: 600;
    line-height: 1.3;
    color: #333;
    max-width: 900px; 
    margin: 0 auto;
    width: 100%;
  }
  
  .testimonialHeader h1 span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .testimonialHeader .desc-text {
    font-size: 18px;
    color: #555;
    margin-top: 10px;
    padding: 0 20px;
    max-width: 900px; 
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Styling for the testimonial slider section */
  .testimonialSlider {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  
  .sliderTrack {
    display: flex;
    animation: slide 150s linear infinite; 
  }
  
  /* Each testimonial card styling */
  .testimonialItem {
    flex-shrink: 0;
    width: 400px;
    height: 300px;
    background: white;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 25px;
  }
  
  /* Styling for the image inside the testimonial card */
  .testimonialImage {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 15px;
    margin-bottom: 10px;
  }
  
  /* Styling for the name and title (role) */
  .name-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
    margin-left: 1px; 
  }
  
  .name-title h5 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
  
  .name-title p {
    font-size: 12px;
    color: #3fb871;
  }
  
  /* Styling for the message (testimonial content) */
  .bottom-item-testimony {
    font-size: 14px;
    font-style: italic;
    color: #000000;
    margin-top: 15px;
  }
  
  .testimonialMessage {
    font-size: 14px;
    font-style: italic;
    color: #555;
    padding: 10px;
    border-left: 4px solid #3fb871;
  }
  
  /* Smooth transition for the sliding effect */
  @keyframes slide {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
  }
  
  /* Prevent slider animation when hovering over any testimonial card */
  .testimonialSlider.paused .sliderTrack {
    animation-play-state: paused;
  }
  
  /* Hover effect on individual testimonial cards */
  .testimonialItem:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
  }
  
  .testimonialItem:hover .testimonialImage {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
  }
  
  .testimonialItem:hover .name-title h5 {
    color: #3fb871;
  }
  
  .testimonialItem:hover .testimonialMessage {
    border-left: 4px solid #5b8a72;
  }
  
  /* Media query for responsiveness on smaller screens */
  @media screen and (max-width: 768px) {
    .testimonialItem {
      width: 10%;
      margin: 10px 10px;
      height: auto;
      padding: 15px;
    }
  
    .sliderTrack {
      animation: slide 120s linear infinite; 
    }
  
    .testimonialHeader h1 {
      font-size: 1.5rem; 
      /* max-width: 100%;  */
      text-align: left;
      padding: 0 15px;
    
    }
  
    .testimonialHeader .desc-text {
      font-size: 16px;
      width: 100%;
      text-align: left;
    }
  }
  