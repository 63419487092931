/* General Styling */
.container-HeroSection {
  position: relative;
  text-align: center;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  
}

.container-HeroSection .textContainerhere {
  z-index: 2;
  max-width: 60%; 
  /* margin: 0 auto; */
}

.container-HeroSection .Cultivaed {
  font-size: 4rem;
  font-weight: 600;
  color: #274C5B;
  margin-bottom: 0px;
}

.container-HeroSection .Premier {
  font-size: 2.8rem;
  line-height: 1.2;
  color: #274C5B;
  margin-bottom: 15px;
}

.container-HeroSection .Prem {
  color: #F7B618;
  font-weight: 600;
}

.container-HeroSection .B2B {
  color: #3FB871;
  font-weight: 600;
}

.container-HeroSection .Reward {
  font-size: 1.3rem;
  color: #555555;
  margin-bottom: 20px;
  width: 80%;
  max-width: 70%;
  margin: auto;
  line-height: 1.5;
}

/* Buttons Styling */
.buttonContainer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; 
  gap: 10px; 
 
}

.buttonContainer .btn {
  padding: 15px 20px;
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 10px 5px; 
  transition: transform 0.3s ease, background-color 0.3s ease;
  min-width: 150px; /* Ensures buttons have a minimum width */
}

.buttonContainer .download-app-btn {
  background-color: #3FB871;
  color: #fff;
}

.buttonContainer .download-app-btn:hover {
  background-color: #3FB871;
  transform: scale(1.1);
  color: #fff;
}

.buttonContainer .read-more-btn {
  background-color: #fdfdfd;
  color: #3FB871;
  border: 1px solid #3FB871;
}

.buttonContainer .read-more-btn:hover {
  background-color: white;
  transform: scale(1.1);
  color: #3FB871;
  border: 1px solid #3FB871;
}


/* Responsive Buttons */
@media (max-width: 768px) {
 
  .buttonContainer .btn {
    font-size: 0.9rem; 
    /* padding: 12px 18px; */
    max-width: 300px; 
  }
}

@media (max-width: 480px) {
  .buttonContainer.btn {
    font-size: 0.9rem; 
    /* padding: 10px 15px;  */
    min-width: 120px; 
  }

  .buttonContainer {
    gap: 5px; 
  }
}

/* Moving Circular and Oval Shapes */
.moving-shapes {
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(to right, #3fb87153, #f7b81847);
  animation: move-shape 5s infinite ease-in-out alternate;
  z-index: 1;
}

.left-shape {
  width: 400px; 
  height: 400px;
  top: 10%;
  left: -5%;
}

.right-shape {
  width: 350px; 
  height: 350px; 
  bottom: 15%;
  right: -10%;
}

.oval-shape {
  border-radius: 50% 30%; 
}

/* Animations */
@keyframes move-shape {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100px);
  }
}

/* Responsive Styles */
@media (max-width: 1024px) {

  .container-HeroSection .textContainerhere {
    max-width: 100%; 
  }
  .container-HeroSection .Cultivaed {
    font-size: 3rem;
  }

  .container-HeroSection .Premier {
    font-size: 2.5rem;
  }

  .container-HeroSection .Reward {
    font-size: 1.2rem;
    width: 100%;
    margin: 0 auto;
  }

  .left-shape,
  .right-shape {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 768px) {

  .container-HeroSection .textContainerhere {
    max-width: 100%; 
    /* margin-top: 50px; */
  }
  .textContainerhere {
    max-width: 100%;
  }

  .container-HeroSection .Cultivaed {
    font-size: 2.5rem;
  }

  .container-HeroSection .Premier {
    font-size: 2rem;
  }

  .container-HeroSection .Reward {
    font-size: 1.1rem;
  }

  .left-shape,
  .right-shape {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 480px) {

  .container-HeroSection .textContainerhere {
    max-width: 100%; 
  }
  .container-HeroSection .Cultivaed {
    font-size: 2rem;
  }

  .container-HeroSection .Premier {
    font-size: 1.8rem;
  }

  .container-HeroSection .Reward {
    font-size: 1rem;
  }

  .left-shape,
  .right-shape {
    width: 200px;
    height: 200px;
  }
}
