
/* General Container */
.join-container {
    /* max-width: 1200px; */
    /* margin: 50px auto; */
    padding: 100px 0px;
    text-align: center;
    background: #F4F4F6;
    font-family: 'Poppins', sans-serif;
}

.initalContainer h1 {
    font-size: 2.5rem;
    color: #274C5B;
    margin-bottom: 20px;
    line-height: 1.3;
    font-weight: 600;
}

.initalContainer h1 span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent ;
}

.desc-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 50px;
    line-height: 1.6;
    width: 50%;
    margin: 0 auto;
}

/* Cards Container */
.cards-top-container {
    display: flex;
    justify-content: space-between;
    gap: 0px;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    margin: 05% auto;
    padding: 10px 50px;
}

/* Individual Card */
.card-item-container {
    flex: 1;
    max-width: 350px;
    background-color: #fff;
    border-radius: 15px;
    /* box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); */
    text-align: center;
    padding: 30px 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cards-top-container .card-item-container .rectangle{
    background: #F1EBFF;
    width: 100%;
    height: 150px;
    border-radius: 10px;
}

.card-item-container:hover {
    transform: translateY(-10px);
    box-shadow: 0px 12px 30px rgba(0, 0, 0, 0.15);
}

/* Card Image */
.img-item-icon {
    width: 50px;
    height: auto;
    margin-bottom: 20px;
}

/* Card Title */
.card-item-container .header-list {
    font-size: 1.5rem;
    color: #274C5B;
    font-weight: 600;
    margin-bottom: 15px;
    text-align: left;
}

/* Card Description */
.cont-desc {
    font-size: .85rem;
    color: #555;
    margin-bottom: 50px;
    line-height: 1.6;
    text-align: left;
}

/* Button */
.join-btn {
    font-size: 1rem;
    font-weight: bold;
    padding: 12px 30px;
    border-radius: 8px;
    border: none;
    background-color: #3FB871;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.join-btn:hover {
    background-color: #36A863;
    transform: translateY(-3px);
}

/* Responsive Design */

/* Tablet View */
@media (max-width: 1024px) {
    .cards-top-container {
        justify-content: center;
        gap: 15px;
    }

    .card-item-container {
        max-width: 300px;
    }

    .initalContainer h1 {
        font-size: 2rem;
    }

    .desc-text {
        font-size: 0.95rem;
    }
}

/* Mobile View */
@media (max-width: 768px) {
    .cards-top-container {
        flex-direction: column;
        align-items: center;
    }

    .card-item-container {
        max-width: 90%;
    }

    .initalContainer h1 {
        font-size: 1.8rem;
    }

    .desc-text {
        font-size: 0.9rem;
    }

    .join-btn {
        font-size: 0.9rem;
        padding: 10px 25px;
    }
}

/* Small Mobile View */
@media (max-width: 480px) {
    .initalContainer h1 {
        font-size: 1.5rem;
    }

    .desc-text {
        font-size: 0.85rem;
    }

    .join-btn {
        font-size: 0.85rem;
    }
}

