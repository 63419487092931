/* General Styles */
.main-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background: white;
    transition: top 0.3s ease, box-shadow 0.3s ease;
    font-family: 'Poppins', sans-serif;
  }
  
  .main-header.show {
    top: 0;
  }
  
  .main-header.hide {
    top: -100px;
  }
  
  /* Header Container */
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  .logo {
    cursor: pointer;
    height: 35px;
  }
  
  /* Desktop Navigation */
  .nav {
    display: flex;
    align-items: center;
  }
  
  .nav-list {
    display: flex;
    gap: 2rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-list li {
    cursor: pointer;
    font-size: .9rem;
    font-weight: 400;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .nav-list li:hover {
    color: #3FB871;
  }
  
  /* Buttons */
  .join-btn {
    background-color: #3FB871;
    color: white;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
  }
  
  .join-btn:hover {
    background-color: #17a351;
  }
  
  .join-btn.desktop {
    display: block;
  }
  
  .hamburger-container {
    display: none;
    cursor: pointer;
  }
  
  .hamburger-icon {
    height: 24px;
    width: 24px;
  }
  
  /* Mobile Menu */
  .mobile-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 2000;
    padding: 2rem;
  }
  
  .mobile-nav-list {
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 1.0rem;
    display: flex;
    flex-direction: column;
  }
  
  .mobile-nav-list li {
    font-size: 1rem;
    cursor: pointer;
    font-weight: 500;
    color: #333;
  }
  
  .mobile-nav-list li:hover {
    color: #3FB871;
  }
  
  /* Close Button */
  .close-btnhead {
    margin-top: auto;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .close-btn:hover {
    background-color: #ddd;
  }
  
  /* Responsive */
  @media (max-width: 768px) {
    .nav {
      display: none;
    }
  
    .hamburger-container {
      display: block;
    }
  
    .join-btn.desktop {
      display: none;
    }

    .logo {
        height: 30px; /* Reduced size for mobile */
      }
  }
  