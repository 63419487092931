/* General Styling */
.missions-visions {
    padding: 50px 20px;
    background-color: #ffffff;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #274c5b;
}

.mission-vision-container {
    max-width: 1200px;
    margin: 0 auto;
}

/* Section Title */
.section-title {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: #274C5B;
}

.mission-vision-container .section-title .missionSpan {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent;
}

/* Section Description */
.section-desc {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #555;
    margin-bottom: 20px;
    max-width: 70%;
    margin: 0 auto 30px auto;
}

/* Cards Container */
.vision-mission-cards {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    padding-bottom: 20px;
}

/* Individual Card */
.missions-visions .vision-mission-cards .card {
    background-color: #ECE9FF;
    border-radius: 8px;
    padding: 50px 20px;
    text-align: center;
    flex: 1 1 300px;
    min-width: 280px;
    max-width: 500px;
    border: none;
    position: relative;
}

.card-image {
    width: 250px;
    height: auto;  
    max-width: 100%;  
    object-fit: contain;  
    margin-bottom: 20px;  
    margin: auto;
}

.card-title {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: 50px;
}

.card-content {
    font-size: 1rem;
    line-height: 1.6;
    color: #555555;
}

/* Responsive Design */
@media (max-width: 768px) {
    .missions-visions {
        padding: 0;
    }
    .mission-vision-container .section-title {
        font-size: 2rem;
        margin: 0px 20px;
        text-align: left;
    }

    .section-desc {
        font-size: 0.9rem;
        text-align: left;
        margin-top: 10px;
    }

    .vision-mission-cards {
        flex-direction: row;
        overflow-x: auto;
        gap: 15px;
        /* padding-bottom: 15px; */
        width: 100%;
        max-width: 100%;
        padding: 10px;
       
    }

    .card-image{
        width: 60%;
    }

    .missions-visions .vision-mission-cards .card {
        min-width: 230px;
        max-width: 350px;
        padding: 15px 10px;
    }

    .section-desc {
        font-size: 1rem;
        max-width: 90%;
    }

    .missions-visions .vision-mission-cards .card .card-content{
        width: 100%;
        padding: 0%;
    }
}

/* Optional: Smooth Scrolling on mobile */
@media (max-width: 768px) {
    .vision-mission-cards {
        -webkit-overflow-scrolling: touch;  /* Smooth scroll */
    }
}
