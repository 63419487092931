.email-taken-container {
    width: 100%;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    background-color: #E9FDF1;

}

.close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.paraxx {
     font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
    color: #274C5B;


}

 

.take {
     font-family: 'Poppins', sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    color: #3FB871;
}

.closeEmail {
    width: 62px;
    height: 62px;
    display: inline;

}

.para {
     font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #274C5B;


}

.contentvc {
    text-align: center;
}

.buttonnex {
    margin-top: 10px;
    padding: 8px 64px;
    background: #3FB871;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

.buttonnex:hover {
    background: #3FB871;
}

/* Media query for small screens */
@media (max-width: 768px) {

.buttonnex {
    margin-top: 10px;
    padding: 1px 37px;
    background: #3FB871;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}
.take {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #3FB871;
}
.closeEmail {
    width: 44px;
    display: block;
    margin-top: -12%;
    height: 44px;
}
.para {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #274C5B;
    text-align: left;
}
.paraxx {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
    color: #274C5B;
}
}

@media (max-width: 360px){
.buttonnex {
    margin-top: 10px;
    padding: 1px 23px;
    background: #3FB871;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}
}

@media  (max-width:280px){
    .buttonnex {
    margin-top: 10px;
    padding: 1px 4px;
    background: #3FB871;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}
.take {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    color: #3FB871;
}

.buttonnex {
    margin-top: 10px;
    padding: 1px 5px !important;
    background: #3FB871;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}
.para {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #274C5B;
    text-align: left;
    width: 119%;
}
.emai {
    color: #3FB871;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 9px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
}
.paraxx {
    font-family: 'Poppins', sans-serif;
    font-size: 9px;
    font-weight: 400;
    line-height: 49px;
    letter-spacing: 0em;
    color: #274C5B;
}
    
}

@media (max-width: 375px) {
    .buttonnex {
      margin-top: 18px;
      padding: 1px 15px;
      background: #3FB871;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;
    }
  }

  @media (max-width: 390px) {
    .buttonnex {
      margin-top: 10px;
      padding: 1px 24px;
      background: #3FB871;
      color: #FFFFFF;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      text-decoration: none;
    }
    .close-icon {
        position: absolute;
        top: 1px;
        right: 7px;
        cursor: pointer;
        width: 23px;
      }
  }

  @media (max-width: 412px) {
    .closeEmail {
      width: 29px;
      display: block;
      margin-top: -12%;
      height: 29px;
    }
    .close-icon {
        position: absolute;
        top: 1px;
        right: 7px;
        cursor: pointer;
        width: 23px;
      }
      .take {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        color: #3FB871;
      }
  }

  @media (max-width: 414px) {
    .closeEmail {
      width: 28px;
      display: block;
      margin-top: -12%;
      height: 29px;
    }
    .close-icon {
        position: absolute;
        top: -2px;
        right: 10px;
        cursor: pointer;
        width: 29px;
      }
  }
  @media (max-width: 428px) {
    .closeEmail {
      width: 28px;
      display: block;
      margin-top: -12%;
      height: 29px;
    }
    .close-icon {
        position: absolute;
        top: -2px;
        right: 10px;
        cursor: pointer;
        width: 29px;
      }
  }

  @media only screen and (max-width: 360px) {
    .email-taken-container {
        width: 100%;
        border-radius: 8px;
        padding: 20px;
        position: relative;
        background-color: #E9FDF1;
        padding-top: 30%;
    }
}


@media only screen and (min-width: 361px) and (max-width: 412px) {
    .email-taken-container {
        width: 100%;
        border-radius: 8px;
        padding: 39px;
        position: relative;
        background-color: #E9FDF1;
        padding-top: 30%;
    }
}


@media only screen and (min-width: 769px) and (max-width: 819px) {
    .email-taken-container {
        width: 100%;
        border-radius: 8px;
        padding: 100px;
        position: relative;
        background-color: #E9FDF1;
    }
}

@media only screen and (min-width: 820px) and (max-width: 1023px) {
    .email-taken-container {
        width: 100%;
        border-radius: 8px;
        padding: 100px;
        position: relative;
        background-color: #E9FDF1;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1123px) {
    .email-taken-container {
        width: 100%;
        border-radius: 8px;
        padding: 100px;
        position: relative;
        background-color: #E9FDF1;
    }
}

@media only screen and (min-width: 1124px) and (max-width: 1255px) {
    .email-taken-container {
        width: 100%;
        border-radius: 8px;
        padding: 100px;
        position: relative;
        background-color: #E9FDF1;
    }
}

@media only screen and (min-width: 413px) and (max-width: 540px) {
    .email-taken-container {
        width: 100%;
        border-radius: 8px;
        padding: 100px;
        position: relative;
        background-color: #E9FDF1;
    }
}

@media only screen and (min-width: 541px) and (max-width: 768px) {
    .email-taken-container {
        width: 100%;
        border-radius: 8px;
        padding: 100px;
        position: relative;
        background-color: #E9FDF1;
    }
}


@media (max-width: 430px)
{
.buttonnex {
    margin-top: 10px;
    padding: 1px 22px;
    background: #3FB871;
    color: #FFFFFF;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}
}