.banner-section {
    width: 100%;
    background-color: #3FB871; 
    padding: 5rem 1rem;
    text-align: center;
    color: #fff; 
    font-family: 'Poppins', sans-serif;

  }
  
  .banner-content {
    max-width: 900px;
    margin: 0 auto;
  }
  
  .banner-statement {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 2rem;
    padding: 0 1rem;
    color: #ffffff; 
  }
  
  .stats-container {
    display: grid; 
    grid-template-columns: repeat(4, 1fr); 
    gap: 2rem; 
    justify-items: center; 
    align-items: center; 
    color: #92EDB7;
  }
  
  .stat-item {
    text-align: center;
  }
  
  .stat-value {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
  }
  
  .stat-label {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
  }
  
  /* Responsive Styles */
  @media (max-width: 1024px) {
    .banner-statement {
      font-size: 1.6rem;
    }
    
    .stats-container {
      grid-template-columns: repeat(2, 1fr); 
    }
  
    .stat-value {
      font-size: 1.8rem;
    }
  
    .stat-label {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 768px) {
    .banner-statement {
      font-size: 1.4rem;
    }
  }
  
  @media (max-width: 480px) {
    .stats-container {
      grid-template-columns: repeat(2, 1fr); /* Ensure 2 items per row on small devices */
      gap: 1.5rem; /* Slightly reduce gap for smaller screens */
    }
  
    .banner-statement {
      font-size: 1.2rem;
    }
  
    .stat-value {
      font-size: 1.6rem;
    }
  
    .stat-label {
      font-size: 0.8rem;
    }
  }
  