/* General Container */
.how-it-works {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* background: linear-gradient(to left, #FFFFFF, #F5F5F5); */
    border-radius: 40px;
    padding: 50px 5%;
    margin: 100px auto;
    max-width: 1200px;
    /* box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); */
    gap: 100px;
    font-family: 'Poppins', sans-serif;
}

/* Left Section */
.left-works {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
   
}

.title-works {
    font-size: 2.5rem;
    font-weight: 600;
    color: #274C5B;
    margin-bottom: 20px;
    line-height: 1.1;
}

.hero-span {
    background: linear-gradient(to left, #3FB871, rgb(247, 182, 24), rgb(63, 184, 113), rgb(63, 184, 113));
    -webkit-background-clip: text;
    color: transparent ;
}

.p-works {
    font-size: 1rem;
    color: #555;
    line-height: 1.2;
    margin-bottom: 30px;
}

/* Bullet Points */
.bullets-works1,
.bullets-works2,
.bullets-works3 {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0px;
    gap: 15px;
}

.bullets-works1 img,
.bullets-works2 img,
.bullets-works3 img {
    width: 40px;
    height: 40px;
    object-fit: contain;
}

.p-bull {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
    flex: 1;
}

/* Button */
.but-works {
    margin-top: 20px;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 15px 30px;
    border-radius: 5px;
    border: 1px solid #3FB871;
    background-color: #ffffff;
    color: #3FB871;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    align-self: flex-start;
}

.but-works:hover {
    background-color: #3FB871;
    transform: translateY(-3px);
    color: #ffffff;
}

/* Right Section */
.right-works {
    /* flex: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
   margin: 0;
}

.work-image {
    /* max-width: 100%; */
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    /* box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); */
}

/* Responsive Design */

/* Tablet View */
@media (max-width: 1024px) {
    .how-it-works {
        flex-direction: column;
        padding: 40px 20px;
    }

    .left-works,
    .right-works {
        width: 100%;
    }

    .title-works {
        font-size: 2.5rem;
        text-align: center;
    }

    .p-works {
        text-align: center;
        font-size: 0.95rem;
    }

    .bullets-works1,
    .bullets-works2,
    .bullets-works3 {
        flex-direction: row;
        gap: 10px;
    }

    .but-works {
        align-self: center;
    }
}

/* Mobile View */
@media (max-width: 768px) {
    .how-it-works {
        padding: 30px 15px;
    }

    .title-works {
        font-size: 2rem;
        text-align: left;
    }

    .p-works {
        font-size: 0.9rem;
        text-align: left;
    }

    .bullets-works1,
    .bullets-works2,
    .bullets-works3 {
        flex-direction: column;
        align-items: flex-start;
    }

    .p-bull {
        font-size: 0.9rem;
    }

    .work-image {
        max-width: 90%;
        margin-top: 20px;
    }

    .but-works {
        font-size: 0.95rem;
        padding: 12px 30px;
    }
}

/* Small Mobile View */
@media (max-width: 480px) {
    .how-it-works {
        padding: 20px 15px;
    }

    .title-works {
        font-size: 1.5rem;
        text-align: left;
    }

    .p-works {
        font-size:16px;
        text-align: left;
    }

    .p-bull {
        font-size: 0.85rem;
        width: 90%;
    }

    .but-works {
        font-size: 0.9rem;
        padding: 10px 25px;
    }
}
